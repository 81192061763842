import React, { Component } from "react"
import { toast } from "react-toastify"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UserAreaMenu from "../components/userAreaMenu"
import {
  FaRegTrashAlt,
  FaShoppingCart,
  FaChevronUp,
  FaUserInjured,
} from "react-icons/fa"
import { MsgDialog } from "../components/common/dialogs"
import {
  formatCurrency,
  apiPayments2SelectPayments,
  getLocalCart,
  setLocalCart,
  initLocalCart,
  sIsNumber,
} from "../utils/functions"
import { getCurrentUser } from "../services/authService"
import { getItems, setItem } from "../services/collectionServices"
import { dialogEmptyParams } from "../config.json"
import SpinnerLoading from "../components/common/spinnerLoading"
import { anonymousUser, emptyStore } from "../config"
import ReactSelect from "react-select"

class Carrello extends Component {
  state = {
    cart: { products: [] },
    dialogObj: { ...dialogEmptyParams },
    user: { ...anonymousUser },
    userIsClient: true,
    loading: true,
    payments: [{ id: 0 }],
    paymentSelected: { id: 0 },
    orderInEvasion: false,
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const user = await getCurrentUser()
    let userIsClient = true
    if (user.id === 0) {
      window.location = "/login"
    }
    try {
      userIsClient = user.relationships.roles.data[0].id === 3
    } catch (error) {}
    this.setState({
      user,
      userIsClient,
    })

    let dbPayments = await getItems("payments/types")
    dbPayments = apiPayments2SelectPayments(dbPayments.data)

    let payments = []
    payments.push({ id: 0, label: "", value: "" })
    for (let i = 0; i < dbPayments.length; i++) {
      payments.push({ ...dbPayments[i] })
    }

    const paymentSelected = payments[0]

    let cart = getLocalCart()
    this.setState({
      cart,
      payments,
      paymentSelected,
      loading: false,
    })
  }

  handlePlusMinusClick = (e, product, target, step) => {
    e.preventDefault()
    const { products } = this.state.cart
    let newProducts = [...products]
    for (let i = 0; i < newProducts.length; i++) {
      if (newProducts[i].id === product.id) {
        if (!sIsNumber(newProducts[i][target])) {
          newProducts[i][target] = 0
        }
        newProducts[i][target] =
          parseInt(newProducts[i][target]) + parseInt(step)
        if (newProducts[i][target] < 0) {
          newProducts[i][target] = 0
        }
        break
      }
    }
    let { cart } = this.state
    cart.products = newProducts
    this.setState({ cart })
    setLocalCart({ ...this.state.cart })
  }

  changeQuantity = (product, target, quantity) => {
    if (quantity === "") {
      return
    }
    const { products } = this.state.cart
    let newProducts = [...products]
    for (let i = 0; i < newProducts.length; i++) {
      if (newProducts[i].id === product.id) {
        newProducts[i][target] = parseInt(quantity)
        if (newProducts[i][target] < 0 || newProducts[i][target] === "") {
          newProducts[i][target] = 0
        }
        break
      }
    }
    let { cart } = this.state
    cart.products = newProducts
    this.setState({ cart })
    setLocalCart({ ...this.state.cart })
  }

  cartTotal = type => {
    const { products } = this.state.cart
    let result = {}
    result.quantity = 0
    result.discountedQuantity = 0
    result.price = 0
    for (let i = 0; i < products.length; i++) {
      let _quantity = parseInt(products[i].quantity) || 0;
      let _discountedQuantity = parseInt(products[i].discountedQuantity) || 0

      result.quantity += _quantity
      result.discountedQuantity += _discountedQuantity

      if (products[i].price >= 0) {
        result.price += _quantity * products[i].price
      }
    }
    if (type === "price") {
      result[type] = formatCurrency(result[type])
    }
    return result[type]
  }

  manageAddPatient = (e, product) => {
    e.preventDefault()
    let patient = ""
    if (product.hasOwnProperty("patient")) {
      patient = product.patient
    }
    const obj = {}
    obj.product_index = product.id
    this.setState({
      dialogObj: {
        open: true,
        type: "input",
        target: "add patient",
        inputText: patient,
        title: "Aggiunta paziente al prodotto",
        text: "Specifica il nome del paziente destinatario di questo prodotto",
        obj,
      },
    })
  }

  manageRemoveProductFromCart = (e, product_index, product) => {
    e.preventDefault()
    const obj = {}
    obj.product_index = product_index
    let text =
      "Confermi l'eliminazione del prodotto: " +
      product.attributes.description +
      "' dal Carrello?"
    this.setState({
      dialogObj: {
        open: true,
        target: "remove product from cart",
        type: "yesno",
        title: "Eliminazione record",
        text,
        obj,
      },
    })
  }

  handleMsgDialogClickNo = () => {
    this.setState({ dialogObj: { open: false } })
  }

  handleMsgDialogClickYes = () => {
    const { dialogObj } = this.state
    let cart = getLocalCart()
    switch (dialogObj.target) {
      case "remove product from cart":
        cart.products.splice(dialogObj.obj.product_index, 1)
        if (cart.products.length === 0) {
          cart.store = { ...emptyStore }
        }
        dialogObj.open = false
        this.setState({ cart, dialogObj })
        setLocalCart(cart)
        break
      case "add patient":
        for (let i = 0; i < cart.products.length; i++) {
          if (cart.products[i].id === dialogObj.obj.product_index) {
            cart.products[i].patient = dialogObj.inputText
          }
        }
        dialogObj.open = false
        this.setState({ cart, dialogObj })
        setLocalCart({ ...cart })
        break
      default:
        break
    }
  }

  handleReturnToOrder = () => {
    window.location = "/ordine"
  }

  handlePaymentChanged = e => {
    const paymentSelected = e
    this.setState({ paymentSelected })
  }

  handleEvadeOrder = async e => {
    e.preventDefault()
    if (this.state.orderInEvasion) {
      return
    }
    this.setState({ orderInEvasion: true }, async () => {
      let cart = getLocalCart()
      const { paymentSelected } = this.state

      // Validazione che non ci siano prodotti a zero euro (tranne i free sample)
      let validatePriceFlag = false
      let product = {}

      for (let i = 0; i < cart.products.length; i++) {
        product = cart.products[i]
        if (
          parseFloat(product.price).toFixed(2) === "0.00" &&
          !product.meta.is_free_sample &&
          !this.state.userIsClient
        ) {
          validatePriceFlag = true
          break
        }
      }

      if (validatePriceFlag) {
        this.setState({ orderInEvasion: false })
        toast.error(
          "Acquisto bloccato! Non ci possono essere prodotti con prezzo a 0€."
        )
        return
      }

      let order = { relationships: { store: { data: { id: cart.store.id } } } }
      if (paymentSelected.id > 0) {
        order.relationships.payment_type = { data: { id: paymentSelected.id } }
      }

      order.relationships.rows = { data: [] }
      for (let i = 0; i < cart.products.length; i++) {
        product = cart.products[i]
        let data = { attributes: { product_id: product.id } }
        data.attributes.quantity = product.quantity
        data.attributes.discount_quantity = product.discountedQuantity

        let rowPrice = null
        if (
          !product.meta.is_free_sample &&
          typeof product.isPriceChanged !== "undefined" &&
          product.isPriceChanged
        ) {
          rowPrice = product.price
        }

        data.attributes.price = rowPrice
        data.attributes.is_free_sample = product.meta.is_free_sample
        data.attributes.patient = product.patient
        order.relationships.rows.data.push(data)
      }
      const orderSent = await setItem("orders", order)
      if (orderSent) {
        initLocalCart()
        let cart = getLocalCart()
        this.setState({ cart })
        toast.success("Ordine inviato con successo")
      }
      this.setState({ orderInEvasion: false })
    })
  }

  handlePriceChange = ({ currentTarget: item }) => {
    if (item.value === undefined || item.value < 0) {
      item.value = "0.00"
    }
    let cart = getLocalCart()
    for (let i = 0; i < cart.products.length; i++) {
      const product = cart.products[i]
      if (product.id === parseInt(item.id)) {
        cart.products[i].price = item.value
        cart.products[i].isPriceChanged = true
      }
    }
    this.setState({ cart })
    setLocalCart({ ...cart })
  }

  handleDialogInputChange = ({ currentTarget: item }) => {
    const { dialogObj } = this.state
    dialogObj.inputText = item.value
    this.setState({ dialogObj })
  }

  handleInputChange = (obj, { currentTarget }) => {
    this.changeQuantity(obj, currentTarget.id, currentTarget.value)
  }

  render() {
    const {
      cart,
      user,
      userIsClient,
      loading,
      payments,
      paymentSelected,
      orderInEvasion,
    } = this.state
    const { dialogObj } = this.state
    return (
      <Layout>
        <SEO
          title="Carrello"
          keywords={[`vision care`]}
          description="Carrello"
        />

        <MsgDialog
          dialogObj={dialogObj}
          onMsgDialogClickNo={this.handleMsgDialogClickNo}
          onMsgDialogClickYes={this.handleMsgDialogClickYes}
          onDialogInputChange={this.handleDialogInputChange}
        />

        {user.id > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12 pt-3">
                <h1 className="page-title">Area Clienti</h1>

                <div className="row">
                  {/*  LEFT */}
                  <div className="col-12">
                    <UserAreaMenu menuItemSelected="Carrello" />
                  </div>

                  {/*  CENTER */}
                  <div className="col-12">
                    <h2 className={"customers-area-title with-icon"}>
                      <FaShoppingCart className={"icon-left"} /> Carrello
                    </h2>

                    {cart.products.length === 0 && !loading && (
                      <p>In questo momento il carrello è vuoto</p>
                    )}

                    {loading && (
                      <div className="col-12 py-5">
                        <SpinnerLoading />
                      </div>
                    )}

                    {cart.products.length > 0 && (
                      <div>
                        <p className="cart-store">
                          Intestato a: <br />
                          <span className={"cart-store-fullname"}>
                            {cart.store.fullName}
                          </span>
                          <br />
                          <span className={"cart-store-address"}>
                            {cart.store.fullAddress}
                          </span>
                        </p>

                        <div className="responsive-table-wrapper">
                          <table className="products-table">
                            <thead>
                              <tr>
                                <th>Prodotto</th>
                                {!userIsClient && <th className="c">Prezzo</th>}
                                <th className="c">Quantità</th>
                                {!userIsClient && (
                                  <th className="c">Sconto Merce</th>
                                )}
                                {!userIsClient && (
                                  <th className="r">Subtotale</th>
                                )}
                                {!userIsClient && <th />}
                                <th />
                              </tr>
                            </thead>

                            <tbody>
                              {cart.products.map((product, product_index) => (
                                <tr key={product.id}>
                                  <td
                                    className={
                                      "products-table-description-cell"
                                    }
                                  >
                                    {product.attributes.description}
                                  </td>

                                  {!userIsClient && (
                                    <td className="products-table-price-cell">
                                      {!product.meta.is_free_sample && product.price >= 0 && (
                                        <div className="products-table-price-group input-group">
                                          <input
                                            type="number"
                                            className="formControl products-table-price-input"
                                            id={parseInt(product.id)}
                                            value={product.price}
                                            step="0.01"
                                            min="0.01"
                                            onChange={this.handlePriceChange}
                                          />
                                          <div className="input-group-prepend">
                                            <span
                                              className="products-table-price-icon input-group-text"
                                              id="basic-addon3"
                                            >
                                              €
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  )}

                                  <td className="c products-table-quantity">
                                    <div className={"quantity-spinner"}>
                                      <button
                                        className="btn btn-secondary products-table-quantity-remove"
                                        onClick={e =>
                                          this.handlePlusMinusClick(
                                            e,
                                            product,
                                            "quantity",
                                            -1
                                          )
                                        }
                                      >
                                        -
                                      </button>

                                      <input
                                        id="quantity"
                                        placeholder="0"
                                        className="form-control products-table-quantity-input"
                                        type="number"
                                        value={product.quantity}
                                        onChange={e =>
                                          this.handleInputChange(product, e)
                                        }
                                      />

                                      <button
                                        className="btn btn-secondary products-table-quantity-add"
                                        onClick={e =>
                                          this.handlePlusMinusClick(
                                            e,
                                            product,
                                            "quantity",
                                            1
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </td>

                                  {!userIsClient && (
                                    <td className="c products-table-quantity">
                                      {!product.meta.is_free_sample && (
                                        <div className={"quantity-spinner"}>
                                          <button
                                            disabled={product.is_free_sample}
                                            className="btn btn-secondary products-table-quantity-remove"
                                            onClick={e =>
                                              this.handlePlusMinusClick(
                                                e,
                                                product,
                                                "discountedQuantity",
                                                -1
                                              )
                                            }
                                          >
                                            -
                                          </button>

                                          <input
                                            id="discountedQuantity"
                                            placeholder="0"
                                            className="form-control products-table-quantity-input"
                                            type="number"
                                            value={product.discountedQuantity}
                                            onChange={e =>
                                              this.handleInputChange(product, e)
                                            }
                                          />

                                          <button
                                            disabled={product.is_free_sample}
                                            className="btn btn-secondary products-table-quantity-add"
                                            onClick={e =>
                                              this.handlePlusMinusClick(
                                                e,
                                                product,
                                                "discountedQuantity",
                                                1
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      )}
                                    </td>
                                  )}
                                  {!userIsClient && (
                                    <td className="r">
                                      {!product.meta.is_free_sample &&
                                        product.price >= 0 &&
                                        formatCurrency(
                                          product.quantity * product.price
                                        )}
                                    </td>
                                  )}

                                  <td className="c-pointer">
                                    <div className="products-table-actions-wrapper">
                                      <button
                                        title="Specifica il nome del paziente"
                                        className="products-table-actions-icon"
                                        onClick={e =>
                                          this.manageAddPatient(e, product)
                                        }
                                      >
                                        <FaUserInjured
                                          className={
                                            product.patient &&
                                            product.patient !== ""
                                              ? "text-primary"
                                              : "text-light-gray"
                                          }
                                        />
                                      </button>

                                      <button
                                        title="Rimuovi questo prodotto dal carrello"
                                        className="products-table-actions-icon"
                                        onClick={e =>
                                          this.manageRemoveProductFromCart(
                                            e,
                                            product_index,
                                            product
                                          )
                                        }
                                      >
                                        <FaRegTrashAlt />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tfoot>
                              <tr className="b">
                                {!userIsClient && <td />}
                                <td className="c">Totale</td>
                                <td className="c">
                                  {this.cartTotal("quantity")}
                                </td>
                                {!userIsClient && (
                                  <td className="c">
                                    {this.cartTotal("discountedQuantity")}
                                  </td>
                                )}
                                {!userIsClient && (
                                  <td className="r">
                                    {this.cartTotal("price")}
                                  </td>
                                )}
                                <td />
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        {payments.length > 1 && (
                          // > 1 perché c'è sempre e comunque il primo elemento del dropdown vuoto, impostato sopra
                          <div className="select-payment-type mt-4 mb-3">
                            <div className={"row"}>
                              <div className={"col-12 col-md-6 col-lg-4"}>
                                <label htmlFor="payments">
                                  Seleziona un metodo di pagamento:
                                </label>
                                <ReactSelect
                                  placeholder="cerca..."
                                  id="payments"
                                  options={payments}
                                  value={paymentSelected}
                                  onChange={this.handlePaymentChanged}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div>
                          <button
                            disabled={
                              cart.products.length === 0 || orderInEvasion
                            }
                            className="btn btn-primary my-2"
                            onClick={e => this.handleEvadeOrder(e)}
                          >
                            Acquista
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default Carrello
